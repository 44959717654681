// const api = window.location.protocol + "//" + window.location.host + "/api";
// const adminUrl = window.location.protocol + "//" + window.location.host;
// const api = 'https://dev2.fwwb.vip/api';   
// const adminUrl = 'https://dev2.fwwb.vip';
const api = 'https://dev.fwwb.vip/api';
// const api = 'http://127.0.0.1:8081'
const adminUrl = 'https://dev.fwwb.vip';
// 基本配置
export const Config = {
    api: {
        url: api,
        adminUrl: adminUrl
    },
};