import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    chooseRoute: '/home'
  },
  mutations: {
    chooseRoute: (state, info) => {state.chooseRoute = info}
  },
  actions: {
  },
  modules: {
  }
})
