import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../pages/layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../pages/Home.vue')
      },
      {
        path: '/cases',
        name: 'Cases',
        component: () => import(/* webpackChunkName: "about" */ '../pages/Cases.vue')
      },
      {
        path: '/about',
        name: 'About',
        redirect: '/about/base',
        component: () => import(/* webpackChunkName: "about" */ '../pages/About.vue'),
        children: [
          {
            path: '/about/base',
            name: 'Base',
            component: () => import(/* webpackChunkName: "about" */ '../pages/about/Base.vue')
          },
          {
            path: '/about/manage',
            name: 'Manage',
            component: () => import(/* webpackChunkName: "about" */ '../pages/about/Manage.vue')
          },
          {
            path: '/about/development',
            name: 'Development',
            component: () => import(/* webpackChunkName: "about" */ '../pages/about/Development.vue')
          },
          {
            path: '/about/company',
            name: 'Company',
            component: () => import(/* webpackChunkName: "about" */ '../pages/about/Company.vue')
          },
          {
            path: '/about/contact',
            name: 'Contact',
            component: () => import(/* webpackChunkName: "about" */ '../pages/about/Contact.vue')
          },
        ]
      },
      {
        path: '/main-business',
        name: 'MainBusiness',
        component: () => import(/* webpackChunkName: "about" */ '../pages/MainBusiness.vue')
      },
      {
        path: '/business',
        name: 'Business',
        component: () => import(/* webpackChunkName: "about" */ '../pages/Business.vue')
      },
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
