<template>
    <div class="header-container">
        <div style="display:flex;align-items: center">
            <img @click="() => { chooseTab('/home') }" class="logo-img" src="../../assets/images/logo.svg" alt="">
            <div class="menu-container">
                <div class="menu-item" @click="() => { chooseTab(item.routeUrl) }" v-for="(item) in mennu" :key="item.routeUrl"> <span :class="item.routeUrl == chooseRoute ? 'choose-tab': ''">{{item.name}}</span> </div>
            </div>
        </div>
        <div class="header-right">
            <img class="phone-icon" src="../../assets/images/phone.svg" alt="">
            <div>招商/投诉专线：400-000-3061</div>
            <div @click="() => { openAdmin() }">后台登陆</div>
        </div>
    </div>
</template>
<script>
import { Config } from "../../utils/index.js"
import { mapState } from "vuex";
export default {
    data() {
        return {
            mennu: [
                {name: '首页', routeUrl: '/home'},
                {name: '主营业务', routeUrl: '/main-business'},
                {name: '商务合作', routeUrl: '/business'},
                {name: '关于三圆', routeUrl: '/about'},
                {name: '经典案列', routeUrl: '/cases'},
            ]
        }
    },
    computed: {
        ...mapState(["chooseRoute"])
    },
    methods: {
        openAdmin() {
            window.open(Config.api.adminUrl)
        },
        chooseTab(url) {
            this.$vuex.commit("chooseRoute", url);
            this.$router.push(url)
        }
    }
}
</script>
<style lang="less" scoped>
.header-container {
    width: 100%;
    height: 100rem;
    display: flex;
    align-items: center;
    background: #fff;
    padding: 0 320rem;
    justify-content: space-between;
    .header-right {
        display: flex;
        .phone-icon {
            width: 20rem;
            margin-right: 10rem;
        }
        div {
            cursor: pointer;
            color: #333;
            font-size: 14rem;
            &:nth-of-type(1) {
                margin-right: 40rem;
            }
        }
    }
    .logo-img {
        height: 100rem;
    }
    .menu-container {
        padding: 0 20rem;
        display: flex;
        height: 100%;
        align-items: center;
        .menu-item {
            padding: 20rem;
            cursor: pointer;
            span {
               color: #333;
               font-size: 18rem;
            }
            .choose-tab {
                color: #4A61F1;
                border-bottom: 1px solid #4A61F1;
            }
        }
    }
}
</style>