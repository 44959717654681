<template>
  <div class="layout">
    <Header />
    <router-view/>
    <Flooter />
  </div>
</template>

<script>
import Header from '../components/layout/Header'
import Flooter from '../components/layout/Flooter'

export default {
  name: 'Home',
  components: {
    Header,
    Flooter
  }
}
</script>
<style lang="less" scoped>
.test {
  width: 100%;
  .test2 {
    height: 100rem;
    width: 100rem;
    background: #f00;
  }
  .test3 {
    height: 100px;
    width: 100px;
    background: #000;
  }
}
</style>
