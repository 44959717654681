<template>
    <div class="foolter-container">
        <div class="foolter-content">
            <div class="foolter-item">
                <div class="item-title">首页</div>
                <div class="item-item" @click="() => { routeFun('/home', '/home?#homeM01') }">旗下企业</div>
                <div class="item-item" @click="() => { routeFun('/home', '/home?#homeM02') }">客户成功案列</div>
                <div class="item-item" @click="() => { routeFun('/home', '/home?#homeM03') }">合作企业</div>
            </div>
            <div class="foolter-item">
                <div class="item-title">关于三圆</div>
                <div class="item-item" @click="() => { routeFun('/about', '/about/base?#aboutTab') }">三圆简介</div>
                <div class="item-item" @click="() => { routeFun('/about', '/about/manage?#aboutTab') }">组织架构</div>
                <div class="item-item" @click="() => { routeFun('/about', '/about/development?#aboutTab') }">发展历程</div>
                <div class="item-item" @click="() => { routeFun('/about', '/about/company?#aboutTab') }">企业文化</div>
                <div class="item-item" @click="() => { routeFun('/about', '/about/contact?#aboutTab') }">联系我们</div>
            </div>
            <div class="foolter-item">
                <div class="item-title">主营业务</div>
                <div class="item-item" @click="() => { routeFun('/main-business', '/main-business?#mainM01') }">运营痛点</div>
                <div class="item-item" @click="() => { routeFun('/main-business', '/main-business?#mainM03') }">三圆六大优势</div>
                <div class="item-item" @click="() => { routeFun('/main-business', '/main-business?#mainM04') }">服务数据</div>
                <div class="item-item" @click="() => { routeFun('/main-business', '/main-business?#mainM05') }">合作模式/报价</div>
                <div class="item-item" @click="() => { routeFun('/main-business', '/main-business?#mainM06') }">服务平台</div>
                <div class="item-item" @click="() => { routeFun('/main-business', '/main-business?#mainM07') }">服务流程</div>
                <div class="item-item" @click="() => { routeFun('/main-business', '/main-business?#mainM08') }">服务承诺</div>
            </div>
            <div style="display:flex;">
                <div class="foolter-item">
                    <div class="item-title">商务合作</div>
                    <div class="item-item" @click="() => { routeFun('/business', '/business') }">合作申请</div>
                </div>
                <div class="item-right">
                    <div>投诉/招商电话</div>
                    <div>400-000-3061</div>
                    <div>浙江省杭州市滨江区长河街道长河路351号</div>
                </div>
            </div>
        </div>
				<div style="position: absolute;left: 50%;">
					<a href="https://beian.miit.gov.cn" rel="nofollow">浙ICP备20006911号-1</a>
				</div>
    </div>
</template>
<script>
export default {
    created() {
        let pathnameArr = window.location.pathname.split('/')
        this.$vuex.commit("chooseRoute",  '/' + pathnameArr[1]);
    },
    methods: {
        routeFun(tab, url) {
            this.$vuex.commit("chooseRoute", tab);
            if (url) {
                // this.$router.push(url)

                window.location.href = url

                // let aHtml = document.createElement("a")
                // aHtml.setAttribute("href", url);
                // aHtml.style.display = 'none'
                // aHtml.click();
            }
        }
    }
}
</script>
<style lang="less" scoped>
.foolter-container {
    width: 100%;
    height: 440rem;
    background: #f7f7f7;
    .foolter-content {
        width: 1200rem;
        margin: 0 auto;
        display: flex;
        padding-top: 60rem;
        justify-content: space-evenly;
        .item-right {
            border-left: 1px solid #999;
            margin-left:38rem;
            padding-left:18rem;
            height:90rem;
            text-align: left;
            div {
                line-height: 24rem;
                &:nth-of-type(2) {
                    font-weight: 700;
                }
            }
        }
        .foolter-item {
            text-align: left;
            
            .item-item {
                color: #333;
                font-size: 18rem;
                line-height: 33rem;
                cursor: pointer;
                &:hover {
                    color: #1890ff;
                }
            }
            .item-title {
                color: #333;
                font-size: 22rem;
                margin-bottom: 50rem;
                cursor: pointer;
            }
        }
    }
}
</style>